<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      disabled: !this.$themeSettings.components.ProductsVisited.enable,
      wrapperClass: 'ProductsVisitedLazy',
      componentFactory: () => import('./ProductsVisited.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.ProductsVisitedLazy > .PlaceholderBox {
  min-height: 595px;

  @include media-breakpoint-up(lg) {
    min-height: 330px;
  }
}
</style>
