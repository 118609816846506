import SubCategoryBox from '~/themes/base/components/category/SubCategoryBox'

export default {
  extends: SubCategoryBox,
  props: {
    mode: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    detailImage() {
      return 'catalog' === this.mode && this.subCategory.category_detail_image
        ? this.subCategory.category_detail_image
        : this.subCategory.image
    },
  },
}
