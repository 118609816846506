<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'HomepageBranchesLazy',
      componentFactory: () => import('./HomepageBranches.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.HomepageBranchesLazy > .PlaceholderBox {
  min-height: 50vh;

  @include media-breakpoint-up(lg) {
    min-height: 460px;
  }
}
</style>
