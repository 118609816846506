<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'SexshopBlogArticlesLazy',
      componentFactory: () => import('./SexshopBlogArticles.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.SexshopBlogArticlesLazy {
  height: 100%;
}

.SexshopBlogArticlesLazy > .PlaceholderBox {
  min-height: 425px;
}
</style>
