import AppImage from '@theme/components/atom/AppImage'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import LazyLoad from 'vanilla-lazyload'

export default {
  components: {
    AppImage,
    AppInput,
    AppTitle,
  },
  props: {
    headingLevel: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      // TODO: Replace hardcoded values
      faq: [
        {
          id: 1,
          title: 'masturbace a erekce',
        },
        {
          id: 2,
          title: 'anální a orální sex',
        },
        {
          id: 3,
          title: 'použití erotických pomůcek',
        },
      ],
    }
  },
  mounted() {
    const pageLazyLoad = new LazyLoad({})
  },
}
