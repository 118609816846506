<script>
import SeoMixin from '~/sites/shop/mixins/SeoMixin'
export default {
  extends: SeoMixin,
  methods: {
    canonicalLinkUrl({ path } = {}) {
      const url = this.createFullPathWithoutBlacklistedParameters({
        path,
        additionalBlacklist: ['IDP'],
      })
      return this.makeAbsoluteUrl(url)
    },
    getSeoLinkCanonical() {
      return [
        {
          rel: 'canonical',
          href: this.canonicalLinkUrl({ path: this.$route.path }),
        },
      ]
    },
    getFontsGoogle() {
      return []
    },
    getFontsLocal() {
      return [
        { rel: 'preload', href: '/fonts/nunitosans-regular-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/nunitosans-bold-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
      ]
    },
  },
}
</script>
