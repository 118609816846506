<template>
  <PageHomepage
    :featured-articles="featuredArticles"
    :block-homepage-content="blockHomepageContent"
    :featured-banners="featuredBanners ? featuredBanners : []"
    :featured-products="{mostSoldProducts, newestProducts}"
    :info-boxes="infoBoxes"/>
</template>

<script>
import AppRouteMixin from '~/mixins/AppRouteMixin'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import PageHomepage from '@theme/components/shop/PageHomepage'

const PAGE = {
  name: 'RouteHomepage',
  nuxtI18n: {
    paths: {
      cs: '/',
      en: '/',
      sk: '/',
    },
  },
  components: {
    PageHomepage,
  },
  mixins: [AppRouteMixin, ImageUrlMixin],
  computed: {
    featuredArticles() {
      return this.articles && this.articles.entities
        ? this.articles.entities.filter(a => a.image !== null && a.image.length > 0)
        : null
    },
  },
  async asyncData({ store, res, app }) {
    if (process.server) {
      res.set('X-Inspishop-Cache-Control', `public, max-age=${app.$env.STATELESS_TTL}`)
    }

    store.commit('locale/SET_CANONICALS', {
      nuxtI18nPaths: PAGE.nuxtI18n.paths,
    })

    const data = {
      blockHomepageContent: app.$themeSettings.components.PageHomepage.showHomepageTextBlock
        ? store.dispatch('homepage/LOAD_HOMEPAGE_CONFIGURATION')
        : null,
      featuredBanners: app.$themeSettings.components.PageHomepage.showSlider
        ? store.dispatch('banner/LOAD_HOMEPAGE_BANNERS')
        : null,
      mostSoldProducts: app.$themeSettings.components.PageHomepage.mostSoldProducts.enable
        ? store.dispatch('product/LOAD_FEATURED_PRODUCTS', { type: 'most_sold', size: 8 })
        : null,
      newestProducts: app.$themeSettings.components.PageHomepage.newestProducts.enable
        ? store.dispatch('product/LOAD_FEATURED_PRODUCTS', { type: 'newest', size: 8 })
        : null,
      articles: app.$themeSettings.components.PageHomepage.featuredArticles.enable
        ? store.dispatch('article/LOAD_ARTICLES', {
            page: 0,
            perPage: app.$themeSettings.components.PageHomepage.featuredArticles.count,
          })
        : [],
      infoBoxes: app.$contentSettings.infoBoxes ? store.dispatch('banner/LOAD_INFO_BOXES') : null,
    }
    let dataResolved = await Promise.allValues(data, [store.dispatch('category/FETCH_TOP_CATEGORIES')])
    if (app.$contentSettings.functions.ssrPropertyPicker && process.server) {
      const mixin = (await import('~/mixins/SSRPropertyPickMixin.vue')).default

      dataResolved = mixin.methods.pickKeysFromPayload({
        payload: dataResolved,
        rules: mixin.computed.ssrPayloadRules(),
        pickers: mixin.computed.ssrPayloadPickers(),
      })
    }

    await app.apolloProvider.defaultClient.cache.reset()
    return dataResolved
  },
}
export default PAGE
</script>
