import AppButton from '@theme/components/atom/AppButton'
import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'

export default {
  components: {
    AppButton,
    AppImage,
    AppTitle,
  },
  props: {
    configuration: Object,
  },
  data() {
    return {
      product: null,
      hours: '00',
      minutes: '00',
      seconds: '00',
      active: false,
      interval: null,
    }
  },
  async mounted() {
    let data = await this.$store.dispatch('product/LOAD_PRODUCTS', {
      ids: [parseInt(this.configuration.product_reference[0].entityId)],
    })
    this.product = data[0]
    this.interval = setInterval(this.calculateTime, 1000)
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    endDateTime() {
      const startDate = new Date(this.configuration.offer_datetime_from || null)
      const endDate = new Date(this.configuration.offer_datetime || null)
      this.active = startDate.getTime() < new Date().getTime() && new Date().getTime() < endDate.getTime()
      return endDate
    },
    roundedDiscount() {
      return Math.round(this.product.prices.percentageDiscount)
    },
    priceDifference() {
      return this.product.prices.originalWithTax - this.product.prices.unitWithTax
    },
    productPath() {
      return this.localePath({
        name: 'product-slug',
        params: { slug: this.product.slug },
      })
    },
  },
  methods: {
    calculateTime() {
      let end = this.endDateTime.getTime()
      let now = new Date().getTime()
      let difference = end - now

      this.hours = Math.floor(difference / (1000 * 60 * 60))
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000)

      this.hours = this.hours < 10 ? '0' + this.hours.toString() : this.hours.toString()
      this.minutes = this.minutes < 10 ? '0' + this.minutes.toString() : this.minutes.toString()
      this.seconds = this.seconds < 10 ? '0' + this.seconds.toString() : this.seconds.toString()

      if (difference < 0) {
        clearInterval(this.interval)
        this.active = false
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
